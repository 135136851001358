export const langs = {
    ENG: {
        //main
        title: 'Renting Cars for the Holidays',
        des: 'Explore the perfect car rental options for your holidays!',
        des_1: "Whether you're planning a road trip or need a reliable vehicle for your travels, we have you covered with a wide range of cars to choose from.",
        des_2: "Contact us today to book your car and make your holiday travel seamless!",
        //about
        abtitle: 'About Us',
        abdesc: 'We provide car rental services for your holidays. Our fleet includes a variety of vehicles to suit your needs. Whether you need a compact car for city driving or an SUV for a family trip, we have you covered.',
        abmission: 'Our mission is to make your travel experience smooth and hassle-free. We offer competitive rates, 24/7 customer support, and flexible rental options to ensure your complete satisfaction',
        email: 'Email us: rentacar@luxurywheels.com',
        call: 'Call us: 0666884425',
        //slide
        stitle: 'Our Catalogue',
        more: 'See more',
        //contact
        contact: 'Contact Us',
        touch: "We'd love to hear from you. Please fill out the form below to get in touch.",
        name: 'Name:',
        yname: 'Your name',
        cemail: 'Email:',
        yemail: 'Your email',
        subject: 'Subject:',
        ysub: 'Subject',
        message: 'Message',
        ymessage: 'Your message',
        send: 'Send Message',
        //cat
        cattitle: 'Our Catalogue',
        catprev: 'Previous',
        catnext: 'Next',
        //navbar
        navhome:'Home',
        navabout:'About',
        navserv:'Services',
        navcata:'Catalogue',
        navconta:'Contact',
        //single
        type:'Type:',
        cap:'Capacity:',
        gaso:'Gasoling:',
        steer:'Steering:',
        price:'Price:',
        day:'Day',
        rentnow:'Rent Now',
        color:'Color',

    },
    FR: {
        //main
        title: 'Location de voitures pour les vacances',
        des: 'Découvrez les options de location de voitures parfaites pour vos vacances !',
        des_1: "Que vous planifiez un road trip ou que vous ayez besoin d'un véhicule fiable pour vos voyages, nous avons ce qu'il vous faut avec un large choix de voitures.",
        des_2: "Contactez-nous dès aujourd'hui pour réserver votre voiture et rendre vos voyages de vacances sans faille !",
        //about
        abtitle: 'À propos de nous',
        abdesc: `Nous fournissons des services de location de voitures pour vos vacances. Notre flotte comprend une variété de véhicules pour répondre à vos besoins. Que vous ayez besoin d'une voiture compacte pour la conduite en ville ou d'un SUV pour un voyage en famille, nous avons ce qu'il vous faut.`,
        abmission: 'Notre mission est de rendre votre expérience de voyage fluide et sans tracas. Nous offrons des tarifs compétitifs, un support client 24/7 et des options de location flexibles pour assurer votre entière satisfaction.',
        email: 'Envoyez-nous un email : rentacar@luxurywheels.com',
        call: 'Appelez-nous : 0666884425',
        //slide
        stitle: 'Notre Catalogue',
        more: 'Voir plus',
        //contact
        contact: 'Contactez-nous',
        touch: 'Nous serions ravis de vous entendre. Veuillez remplir le formulaire ci-dessous pour nous contacter.',
        name: 'Nom :',
        yname: 'Votre nom',
        cemail: 'Email :',
        yemail: 'Votre email',
        subject: 'Sujet :',
        ysub: 'Sujet',
        message: 'Message',
        ymessage: 'Votre message',
        send: 'Envoyer le message',
        //cat
        cattitle: 'Notre Catalogue',
        catprev: 'Précédent',
        catnext: 'Suivant',
        //navbar
        navhome:'Accueil',
        navabout:'À propos',
        navserv:'Services',
        navcata:'Catalogue',
        navconta:'Contact',
        //single
        type:'Type:',
        cap:'Capacité:',
        gaso:'Carburant:',
        steer:'Transmission:',
        price:'Prix:',
        day:'Jour',
        rentnow:'Louer maintenant',
        color:'Couleur',
        
    }
};

import React from 'react';
import { useInView } from 'react-intersection-observer';
import './AboutUS.css';
import AOS from 'aos';
import Map from '../Googlemap/Map';
import { langs } from '../lan';
import { useSelector } from 'react-redux';


export const AboutUS = () => {
  const lang = useSelector((state)=>state.lang.lang)
  return (
    <section id="about" class="bg-zinc-800 text-white py-20">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center">
        <div class="md:w-1/2 mb-8 md:mb-0 md:pr-8 text-justify">
            <h2 class="text-3xl font-bold mb-4">{langs[lang].abtitle}</h2>
            <p class="text-lg">{langs[lang].abdesc}</p>
            <p class="text-lg mt-4">{langs[lang].abmission}</p>
                
          <p className="hover:text-yellow-500 ">
          Hay Al Matar 62010 - Nador (M)
          <br></br>{langs[lang].email}
          <br></br>{langs[lang].call}
          
          </p>

        </div>
          <Map class="md:w-1/2 border-4 border-gray-500 rounded-xl"/>

    </div>
</section>
  );
};

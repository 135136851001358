import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import './Navbar.css';
import { langs } from '../lan';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  const lang = useSelector((state)=>state.lang.lang);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-zinc-800 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center w-full">
            <div className="hidden md:flex space-x-4">
              <a href="#home" className="text-gray-100 hover:bg-yellow-500 no-underline hover:text-black px-3 py-2 rounded-md text-sm font-medium">{langs[lang].navhome}</a>
              <a href="#about" className="text-gray-100 hover:bg-yellow-500 no-underline hover:text-black px-3 py-2 rounded-md text-sm font-medium">{langs[lang].navabout}</a>
              <a href="#services" className="text-gray-100 hover:bg-yellow-500 no-underline  hover:text-black px-3 py-2 rounded-md text-sm font-medium">{langs[lang].navserv}</a>
              <Link to="/all" className="text-gray-100 hover:bg-yellow-500 no-underline hover:text-black px-3 py-2 rounded-md text-sm font-medium">{langs[lang].navcata}</Link>
              <a href="#contact" className="text-gray-100 hover:bg-yellow-500 hover:text-black px-3 py-2 no-underline rounded-md text-sm font-medium">{langs[lang].navconta}</a>
            </div>
            <div className="ml-auto flex-shrink-0">
              <img className="h-8 md:h-10" src="LW-logo.png" alt="Logo" />
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-100 hover:text-black hover:bg-yellow-400 focus:outline-none focus:bg-yellow-400 focus:text-black"
            >
              <MenuIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-150 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu" ref={ref}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col items-center">
              <a href="#home" className="no-underline text-gray-100 hover:bg-yellow-400 hover:text-black block px-3 py-2 rounded-md text-base font-medium">{langs[lang].navhome}</a>
              <a href="#about" className="no-underline text-gray-100 hover:bg-yellow-400 hover:text-black block px-3 py-2 rounded-md text-base font-medium">{langs[lang].navabout}</a>
              <a href="#services" className="no-underline text-gray-100 hover:bg-yellow-400 hover:text-black block px-3 py-2 rounded-md text-base font-medium">{langs[lang].navserv}</a>
              <Link to="/all" className="no-underline text-gray-100 hover:bg-yellow-500 hover:text-black px-3 py-2 no-underline rounded-md text-sm font-medium">{langs[lang].navcata}</Link>
              <a href="#contact" className="no-underline text-gray-100 hover:bg-yellow-400 hover:text-black block px-3 py-2 rounded-md text-base font-medium">{langs[lang].navconta}</a>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

export const Navbarall = () => {
  const lang = useSelector((state)=>state.lang.lang);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-zinc-800 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center w-full">
            <div className="hidden md:flex space-x-4">
              <Link to="/" className="no-underline text-gray-100 hover:bg-yellow-500 hover:text-black px-3 py-2 rounded-md text-sm font-medium">{langs[lang].navhome}</Link>
            </div>
            <div className="ml-auto flex-shrink-0">
              <img className="h-8 md:h-10" src="LW-logo.png" alt="Logo" />
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-100 hover:text-black hover:bg-yellow-400 focus:outline-none focus:bg-yellow-400 focus:text-black"
            >
              <MenuIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-150 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu" ref={ref}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col items-center">
              <Link to="/" className="no-underline text-gray-100 hover:bg-yellow-400 hover:text-black block px-3 py-2 rounded-md text-base font-medium">{langs[lang].navhome}</Link>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

export const Navbarsingle = () => {
  const lang = useSelector((state)=>state.lang.lang);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-zinc-800 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center w-full">
            <div className="hidden md:flex space-x-4">
            <Link to="/" className="no-underline text-gray-100 hover:bg-yellow-500 hover:text-black px-3 py-2 rounded-md text-sm font-medium">{langs[lang].navhome}</Link>
            <Link to="/all" className="text-gray-100 hover:bg-yellow-500 no-underline hover:text-black px-3 py-2 rounded-md text-sm font-medium">{langs[lang].navcata}</Link>            </div>
            <div className="ml-auto flex-shrink-0">
              <img className="h-8 md:h-10" src="/LW-logo.png" alt="Logo" />
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-100 hover:text-black hover:bg-yellow-400 focus:outline-none focus:bg-yellow-400 focus:text-black"
            >
              <MenuIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-150 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu" ref={ref}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col items-center">
              <Link to="/" className="no-underline text-gray-100 hover:bg-yellow-400 hover:text-black block px-3 py-2 rounded-md text-base font-medium">{langs[lang].navhome}</Link>
              <Link to="/all" className="no-underline text-gray-100 hover:bg-yellow-500 hover:text-black px-3 py-2 no-underline rounded-md text-sm font-medium">{langs[lang].navcata}</Link>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { langs } from '../lan';

export const ContactUS = () => {
    const lang = useSelector((state)=>state.lang.lang)
    const [Name,setName] = useState("")
    const [Email,setEmail] = useState("")
    const [Subject,setSubject] = useState("")
    const [Message,setMessage] = useState("")
   
  return (
    <section id="contact" class="bg-zinc-800 text-white py-20 relative overflow-hidden contact-section">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center">
        <div class="w-full md:w-1/2 mb-8 md:mb-0">
            <h2 class="text-3xl font-bold mb-4">{langs[lang].contact}</h2>
            <p class="text-lg mb-8">{langs[lang].touch}</p>
            <form class="w-full">
                <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-white">{langs[lang].name}</label>
                    <input type="text" id="name" name="name" onChange={(e)=>{setName(e.target.value)}}
                        class="w-full mt-1 px-3 py-2 text-black bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500"
                        placeholder={langs[lang].yname} required/>
                </div>
                <div class="mb-4">
                    <label for="email" class="block text-sm font-medium text-white">{langs[lang].cemail}</label>
                    <input type="email" id="email" name="email"  onChange={(e)=>{setEmail(e.target.value)}}
                        class="w-full mt-1 px-3 py-2 text-black bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500"
                        placeholder={langs[lang].yemail} required/>
                </div>
                <div class="mb-4">
                    <label for="subject" class="block text-sm font-medium text-white">{langs[lang].subject}</label>
                    <input type="text" id="subject" name="subject"  onChange={(e)=>{setSubject(e.target.value)}}
                        class="w-full mt-1 px-3 py-2 text-black bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500"
                        placeholder={langs[lang].ysub} required/>
                </div>
                <div class="mb-4">
                    <label for="message" class="block text-sm font-medium text-white">{langs[lang].message}</label>
                    <textarea id="message" name="message" rows="5"  onChange={(e)=>{setMessage(e.target.value)}}
                        class="w-full mt-1 px-3 py-2 text-black bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500"
                        placeholder={langs[lang].ymessage} required></textarea>
                </div>
                <div>
                <a href={`mailto:rentacar@luxwheelsrent.com?subject=${Subject}&body=${Message}`}><button type="button"
                        class="w-full px-4 py-2 bg-yellow-500 text-black font-bold rounded-md hover:bg-yellow-600">{langs[lang].send}</button></a>
                </div>
            </form>
        </div>
        <div class="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center items-center">
            <img src="LW-logo.png" alt="Contact Us" class="max-w-full h-auto pl-12"/>
        </div>
    </div>
</section>
  )
}

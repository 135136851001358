
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './Home/Main/Main';
import {Navbar,Navbarall, Navbarsingle} from './Home/Navbar/Navbar';
import {SlideShow} from './Home/SlideShow/SlideShow';
import {AboutUS} from './Home/AboutUS/AboutUS';
import {ContactUS} from './Home/ContactUS/ContactUS';
import {Footer} from './Home/Footer/Footer';
import Allcars from './Home/Allcars/Allcars';
import Car from './Home/Car/Car';

const Home = () => (
  <>
    <Navbar />
    <Main />
    <AboutUS />
    <SlideShow />
    <ContactUS />
  </>
);
const All = ()=>(
  <>
  <Navbarall/>
  <Allcars/>
  </>
)

const Single = ()=>(
  <>
  <Navbarsingle/>
  <Car/>
  </>
)

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/all' element={<All/>} />
        <Route path='/car/:id' element={<Single/>}/>
      </Routes>
    </BrowserRouter>
      <Footer  />
  </div>
);

export default App;

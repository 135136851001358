import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { langs } from '../lan';
import './Main.css'; 

export default function Main() {
  const lang = useSelector((state)=>state.lang.lang)

  return (
    <section id="home" class="home-section">
        <div class="m-14 bg-gray-500/25 p-4 sm:p-4 md:p-12 xl:p-44 rounded-xl animate-fade-in">
            <h1 class="text-4xl font-bold mb-4">{langs[lang].title}</h1>
            <p class="text-lg leading-relaxed">{langs[lang].des}</p>
			<p class="mt-2 text-lg leading-relaxed hidden sm:block">{langs[lang].des_1}</p>
			<p class="mt-4">{langs[lang].des_2}</p>
        </div>
    </section>
  )
}

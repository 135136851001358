import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SlideShow.css'; // Assuming you have custom styles in SlideShow.css
import axios from 'axios';
import { Link } from 'react-router-dom';
import { langs } from '../lan';
import { useSelector } from 'react-redux';

export const SlideShow = () => {
  const lang = useSelector((state)=>state.lang.lang)
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://luxwheelsrent.com/api/cars');
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return     <section id="services" className="bg-zinc-900 text-white py-20">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold mb-4">{langs[lang].stitle}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
        <p>Loading...</p>
      </div>
      <div className="flex justify-end mt-8">
        <div className="flex rounded-lg shadow-md">
          <a href="/all">
            <button className="text-gray-100 bg-gray-800 hover:text-black hover:bg-yellow-400 px-3 py-2 rounded-md text-sm font-medium focus:outline-none">
            {langs[lang].more}
            </button>
          </a>
        </div>
      </div>
    </div>
  </section>;
  }

  if (error) {
    return <p>Error loading data</p>;
  }

  return (
    <section id="services" className="bg-zinc-900 text-white py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-4">{langs[lang].stitle}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {data && data.data.slice(0, 4).map((car, index) => (
            <Link to={`/car/${car.id}`} key={car.id} className="no-underline text-white bg-gray-800 rounded-lg overflow-hidden shadow-md relative">
            {/* // <div className="no-underline text-white bg-gray-800 rounded-lg overflow-hidden shadow-md relative"> */}
              <img
                src={`https://luxwheelsrent.com/pictures/${car.Picture}`}
                alt={`Car ${index + 1}`}
                className="w-full h-48 object-cover hover:scale-125 transition-transform duration-300 z-0"
              />
              <div className="p-4 z-10 bg-gray-800 relative">
                <h3 className="text-xl font-bold mb-2 no-underline">{car.Name}</h3>
                <p className="text-gray-300 no-underline">{car.description}</p>
                {/* <p className="text-gray-300 mt-2">Price: {car.Price}dh/day</p> */}
              </div>
            {/* </div> */}
            </Link>
          ))}
        </div>
        <div className="flex justify-end mt-8">
          <div className="flex rounded-lg shadow-md">
            <Link to="/all">
              <button className="text-gray-100 bg-gray-800 hover:text-black hover:bg-yellow-400 px-3 py-2 rounded-md text-sm font-medium focus:outline-none">
              {langs[lang].more}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Car.css';
import { langs } from '../lan';
import { useSelector } from 'react-redux';

export default function Car() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [largeImage, setLargeImage] = useState(''); // State to hold the large image
    const [smallImages, setSmallImages] = useState(['', '', '']); // State for small images
    const { id } = useParams();
    const lang = useSelector((state) => state.lang.lang);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://luxwheelsrent.com/api/cars/${id}`);
                setData(response.data);
                setLargeImage(response.data.Picture); // Set the initial large image
                const newSmallImages = [
                    getimagename(1, response.data),
                    getimagename(2, response.data),
                    getimagename(3, response.data),
                ];
                setSmallImages(newSmallImages); // Set small images in an array
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchData();

        // Add window resize listener to update width dynamically
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [id]);

    const getimagename = (n, data) => {
        if (!data || !data.Picture) {
            return '';
        }
        const full = data.Picture.split('.');
        full[0] = full[0] + "_" + n; // Append _n to create different versions of the image
        return full.join('.');
    };

    const handleImageClick = (index) => {
        // Swap the clicked small image with the large image
        const newLargeImage = smallImages[index]; // Get the clicked small image
        const newSmallImages = [...smallImages]; // Create a copy of the small images
        newSmallImages[index] = largeImage; // Replace the clicked small image with the current large image
        setLargeImage(newLargeImage); // Set the new large image
        setSmallImages(newSmallImages); // Update the small images state
    };

    const getImageStyle = () => {
        if (windowWidth <= 1024) {
            return {
                minWidth: '30%',
                maxHeight: '50%',
                height: 'auto',
                width: 'auto',
            };
        } else {
            return {
                maxHeight: '200px',
                maxWidth: '320px',
                height: 'auto',
                width: 'auto',
            };
        }
    };

    if (loading) {
        return (
            <section id="services" className="bg-zinc-900 text-white py-5">
                <div className="flex flex-col min-h-screen">
                    <div className="flex-1 p-2">
                        <div className="px-7 grid grid-cols-1 sm:grid-cols-2 space-x-4 sm:space-y-7">
                            <div className="bg-zinc-800 rounded-md content-stretch p-5 flex flex-col justify-between">
                                <div className="flex space-x-5">
                                    <p className="font-bold text-3xl">Loading...</p>
                                </div>
                                <br />
                                <p className="text-gray-500">Loading...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    if (error) {
        return <div className="text-red-500">Error: {error.message}</div>;
    }

    return (
        <section id="services" className="bg-zinc-900 text-white py-5">
            <div className="flex flex-col min-h-screen">
                <div className="flex-1 p-2">
                    <div className="px-7 grid grid-cols-1 sm:grid-cols-2 space-x-4 sm:space-y-7">
                        <div className="w-fit p-7">
                            <div className="bg-zinc-800 h-min rounded-md text-white overflow-hidden shadow-md">
                            <div className="flex justify-center">
                                <img
                                    src={`https://luxwheelsrent.com/pictures/${largeImage}`} // Use the state for large image
                                    className="rounded-md" // Remove hover and ensure it takes up full width of the container
                                    alt="Car"
                                    style={{
                                        width:'100%',
                                        height:'100%',
                                    }}
                                />
                            </div>
                            </div>
                            <div className="pt-4 space-x-6 grid grid-cols-3 justify-between items-center">
                                {/* Render the small images */}
                                {smallImages.map((smallImage, index) => (
                                    <div key={index} className="overflow-hidden rounded-md">
                                        <img
                                            src={`https://luxwheelsrent.com/pictures/${smallImage}`}
                                            className="hover:scale-125 transition-transform duration-300 cursor-pointer"
                                            alt={`Car Image ${index + 1}`}
                                            onClick={() => handleImageClick(index)} // Pass index to handle click
                                            style={getImageStyle()} // Apply responsive style here
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="bg-zinc-800 rounded-md content-stretch p-5 flex flex-col justify-between">
                            <div className="flex space-x-5">
                                <p className="font-bold text-3xl">{data.Name}</p>
                            </div>
                            <br />
                            <p className="text-gray-500">{data.description}</p>
                            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-4 py-6">
                                <div className="text-gray-200">Model:</div>
                                <div className="text-gray-300 font-bold">{data.Model}</div>
                                <div className="text-gray-200">{langs[lang].type}</div>
                                <div className="text-gray-400 font-semibold">{data.TypeCar}</div>
                                <div className="text-gray-200">{langs[lang].cap}</div>
                                <div className="text-gray-400 font-semibold">{data.Capacity}</div>
                                <div className="text-gray-200">{langs[lang].gaso}</div>
                                <div className="text-gray-400 font-semibold">{data.Gas} L</div>
                                <div className="text-gray-200">{langs[lang].steer}</div>
                                <div className="text-gray-400 font-semibold">{data.Steer}</div>
                                <div className="text-gray-200">{langs[lang].color}:</div>
                                <div
                                    className="w-8 h-8 rounded-md border"
                                    style={{ backgroundColor: data.Color, borderColor: data.Color }}
                                ></div>
                            </div>
                            <div className="mt-auto">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <button className="bg-yellow-600 hover:bg-yellow-400 p-2 px-5 rounded text-white font-semibold">{langs[lang].rentnow}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changelang } from '../../slice';

export const Footer = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state)=>state.lang.lang)
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLangClick = (lang) => {
    dispatch(changelang(lang));
    setDropdownOpen(false); // Close dropdown when a language is selected (optional)
    // You can add logic here to handle language change if needed
  };

  return (
    <footer className="bg-zinc-800 text-white py-8 relative z-20">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center justify-between">
        <div className="text-center md:text-left md:mb-0">
          <p>&copy; 2024 Luxury Wheels. All rights reserved.</p>
        </div>
        <div className="flex space-x-4 mb-4 relative">
          <a href="https://www.facebook.com/people/Luxury-Wheels/61561829019056/" className="hover:text-yellow-500 text-gray-100 no-underline">Facebook</a>
          <a href="https://www.instagram.com/realluxurywheels?igsh=N2pxa2NqZ3N1Z2Yz" className="hover:text-yellow-500 text-gray-100 no-underline">Instagram</a>
          <div className="relative inline-block text-left">
            <button onClick={toggleDropdown} className="hover:text-yellow-500 text-gray-100">
            {lang}
            </button>
            {dropdownOpen && (
              <div className="origin-bottom-right absolute right-0 bottom-8 w-48 rounded-md shadow-lg bg-yellow-500 ring-1 ring-black ring-opacity-5 z-30">
                <div className="py-1">
                  <button
                    onClick={() => handleLangClick('ENG')}
                    className={`block px-4 py-2 text-sm text-gray-900 hover:bg-yellow-400 w-full text-center ${
                      lang === 'ENG' ? 'bg-yellow-400' : ''
                    }`}
                  >
                    ENG
                  </button>
                  <button
                    onClick={() => handleLangClick('FR')}
                    className={`block px-4 py-2 text-sm text-gray-900 hover:bg-yellow-400 w-full text-center ${
                      lang === 'FR' ? 'bg-yellow-400' : ''
                    }`}
                  >
                    FR
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

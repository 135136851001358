import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    initialState:{
        lang:'ENG',

    },
    name:'slice',
    reducers:{
        changelang: (state,action)=>{state.lang=action.payload},
    }
})

export default slice;
export const { changelang } = slice.actions;
import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link } from 'react-router-dom';
import { langs } from '../lan';
import { useSelector } from 'react-redux';
// import "./Allcars.css";
export default function Allcars() {
    const lang = useSelector((state)=>state.lang.lang)
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [Previous, setPrevious] = useState(null);
    const [Next, setNext] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
              setLoading(true);
              const response = await axios.get(`https://luxwheelsrent.com/api/cars?page=${page}`);
              setData(response.data);
              setLoading(false);
      
              const prevLink = response.data.links.find((link) => link.label.includes("Previous"));
              const nextLink = response.data.links.find((link) => link.label.includes("Next"));
      
              setPrevious({ url: prevLink ? prevLink.url : null, status: !!prevLink });
              setNext({ url: nextLink ? nextLink.url : null, status: !!nextLink });
            } catch (err) {
              setError(err);
              setLoading(false);
            }
          };
      
          fetchData();
    }, [page]);
    if (Previous && Previous.url==null) {
        Previous.status=false    
    }else if(Previous && Previous.url!=null){
        Previous.status=true
    }
    if (Next && Next.url==null) {
        Next.status=false
    }else if(Next && Next.url!=null){
        Next.status=true
    }

  if (loading) return <section id="services" class="bg-zinc-900 min-h-screen text-white py-20">
    <div class="max-w h-max-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-3xl font-bold mb-4">{langs[lang].cattitle}</h2>
        <p>Loading...</p>
    </div>
</section>;
  if (error) return <p>Error: {error.message}</p>;
  return (

<>
<section id="services" class="bg-zinc-900 min-h-screen text-white py-20">
    <div class="max-w h-max-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-3xl font-bold mb-4">{langs[lang].cattitle}</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
			{data.data.map((car,index)=>
            <Link to={`/car/${car.id}`} key={car.id} className="no-underline text-white bg-gray-800 rounded-lg overflow-hidden shadow-md relative">
            {/* <div className="no-underline text-white bg-gray-800 rounded-lg overflow-hidden shadow-md relative"> */}
              <img
                src={`https://luxwheelsrent.com/pictures/${car.Picture}`}
                alt={`Car ${index + 1}`}
                className="w-full h-48 object-cover hover:scale-125 transition-transform duration-300 z-0"
              />
              <div className="p-4 z-10 bg-gray-800 relative">
                <h3 className="text-xl font-bold mb-2 no-underline">{car.Name}</h3>
                <p className="text-gray-300 no-underline">{car.description}</p>
                {/* <p className="text-gray-300 mt-2">Price: {car.Price}dh/day</p> */}
              </div>
            {/* </div> */}
          </Link>
			)}
            
		</div>
        <div className='flex flex-row m-auto justify-center '>
                    <button className='btn btn-outline-warning m-3' type='button' id='Previous' onClick={(e)=>setPage(page-1)} disabled={!Previous.url}>{langs[lang].catprev}</button>
                    <button className='btn btn-outline-warning m-3' type='button' id='Next' onClick={(e)=>setPage(page+1)} disabled={!Next.url}>{langs[lang].catnext}</button>
            {/* )} */}
            </div>
    </div>
</section>

</>
  )
}
